.user-page {
  display: flex;
  width: 100%;
  justify-content: center;

  .tab-component-wrapper {
    width: 100%;
  }
}
.user-overview {
  width: 100%;
  max-width: 800px;

  &--tab-wrapper {
    width: 100%;
    padding: 20px;
  }
  &--button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .button-component-wrapper {
      width: 10%;
      margin: unset;
    }
  }
}

.user-edit-form {
  max-width: 800px;
  width: 100%;

  &--button-wrapper {
    display: flex;
    justify-content: space-evenly;

    .delete-button {
      background: #9e0000;
    }
    .button-component-wrapper {
      width: 25%;
    }
  }
}
