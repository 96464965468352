.completed-check {
  &--wrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    .file-input--wrapper {
      flex: 1;
    }
  }
}
