@import "variables.scss";
.company-edit-form {
  max-width: 800px;
  width: 100%;
  .company__locations-wrapper {
    padding: 0 4%;
  }
  .error-label {
    color: $error-color;
  }

  .submit-button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
