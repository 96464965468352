.config-page {
  margin: 50px 0;
  .tab-wrapper {
    width: 100%;
    padding: 20px;
  }
  &--button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .button-component-wrapper {
      width: 10%;
      margin: unset;
    }
  }
}

.bottle-config-form {
  &--button-wrapper {
    display: flex;
    justify-content: space-evenly;

    .delete-button {
      background: #9e0000;
    }
    .button-component-wrapper {
      width: 25%;
    }
  }

  .max-shot {
    &__wrapper {
      display: flex;
      gap: 12px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .input-component-wrapper {
      margin: 0 auto;
    }
  }

  .checkboxContainer {
    padding: 20px;
  }
}
