.bottle-page-wrapper {
  height: 100%;
  #bottle-overview-component {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    h2 {
      text-align: center;
    }

    p {
      margin: 0;
      margin-left: 10%;
    }

    .bottle-overview-plan {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 75%;
      z-index: -1;
    }
  }
  #bottle-check-component {
    .tab-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;

      .title {
        margin: 15px 15px 0 15px;
      }
    }

    .input-component-wrapper {
      input {
        text-align: left;
        font-size: 1rem;
      }
    }
  }

  #bottle-files-component {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 0 auto;

    .file-icon-component-wrapper {
      width: 100% !important;
    }
  }
}
