body {
  margin: 0;
  overscroll-behavior: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.clickable {
  cursor: pointer;
}

.centered-text {
  text-align: center;
}

.bold-text {
  font-weight: bold;
}

.darkred-text {
  color: darkred;
}

.margin-top {
  margin-top: 25px !important;
}

.agrichema-page-logo {
  margin: 100px 0;
}

.hide-this {
  display: none;
}

.break-line {
  border-bottom: 1px solid lightgray;
  width: 90%;
  margin: 0 auto;
}

.horizontal-line-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.vertical-line-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .vertical-line {
    height: 25px;
    background: black;
    width: 2px;
    display: flex;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.layout-component-wrapper {
  #layout-component-content {
    padding: 0px !important;
  }

  #layout-component-header img {
    height: auto !important;
    width: 25% !important;
  }
}

.layout-component-wrapper #layout-component-header img {
  max-width: 150px !important;
}

.red-button {
  background: darkred;
}

.layout-component-menu-icon p {
  padding: 0 15px;
}

.table-date-background {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: -16px;
  padding: 5%;
  color: black;
  font-size: 10px;

  &-green {
    @extend .table-date-background;
    font-weight: bold;
    background-color: green;
    color: white;
  }

  &-yellow {
    @extend .table-date-background;
    font-weight: bold;
    background-color: orange;
  }

  &-red {
    @extend .table-date-background;
    font-weight: bold;
    background-color: red;
  }
}

.spread-horizontally {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 15px;
}

.delete-button {
  background: #9e0000 !important;
}