.inventory-page,
.document-page {
  margin: 50px 0;

  .tab-component-wrapper {
    width: 95%;
  }

  .overview {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .checkIcon {
    fill: green;
    height: 24px;
    width: 24px;
  }

  .missingIcon {
    fill: red;
    height: 24px;
    width: 24px;
  }

  .tab-component-wrapper {
    .tab-component-content {
      flex-direction: column;
    }
  }

  .singleDocumentContainer {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;

    .singleDocument {
      padding-left: 20px;
      padding-right: 20px;
      cursor: pointer;
    }
  }

  .document-page--button-wrapper {
    button {
      min-width: 80px;
    }
  }

  .document-page--button-wrapper-right {
    display: flex;
    margin-right: 10%;
    gap: 20px;

    button {
      min-width: 140px;
      width: 100%;
    }
  }

  &--button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .button-component-wrapper {
      width: fit-content;
      min-width: 120px;
      margin: 0 5px;
    }

    .button-component-wrapper.add-button {
      margin: 0 0 0 auto;
      width: fit-content;
      min-width: 120px;
    }

    .button-component-wrapper.reset-button {
      margin: unset;
      width: fit-content;
      background-color: #9e0000;
    }

    .button-component-wrapper.delete-button {
      width: fit-content;
      background-color: #9e0000;
    }
  }

  &--bottle-filter-container {
    border-radius: 5px;
    padding: 20px;
    transition: all 0.5s ease;
    height: fit-content;

    .inventory-page--bottle-filter-components {
      display: flex;
      gap: 2rem;

      @media (max-width: 600px) {
        flex-wrap: wrap;
      }
    }

    .inventory-page--bottle-filter-result {
      opacity: 0.6;
      font-size: 12px;
    }

    .button-wrapper {
      display: flex;
      align-items: center;

      svg {
        margin-left: auto;
      }
    }
  }

  &--filter-container {
    padding: 10px;
  }

  &-history {
    &--wrapper {
      width: 90%;
      margin: 0 auto;
    }

    &--header {
      display: flex;
      align-items: center;

      .button-wrapper {
        margin-left: auto;
      }
    }

    &--error {
      color: red;
    }

    &--container-wrapper {
      display: flex;
      flex-direction: column;
      gap: 50px;
    }

    &--container {
      gap: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .difference-text {
        position: absolute;
        top: -46px;
        left: 0;
        font-size: 12px;
        opacity: 0.6;
      }
    }
  }

  .inventory-form {
    .bottle-notes-container {
      transition: all 0.5s ease;
      display: flex;
      flex-wrap: wrap;
      padding: 0 5%;

      .button-wrapper {
        display: flex;
        align-items: center;
        flex: 1;
        flex-basis: 100%;

        svg {
          margin-left: auto;
        }
      }

      .notes-wrapper {
        flex-basis: 100%;
        display: flex;
        flex-direction: column;

        .input-component-wrapper {
          width: 100%;
          margin: 2% 0;
        }
      }
    }

    .table-component-wrapper {
      width: 90%;
    }

    .delete-button {
      background: #9e0000;
      max-width: 235px;
    }

    &--button-wrapper {
      display: flex;
      justify-content: space-evenly;

      .button-component-wrapper {
        width: 25%;
      }
    }

    &--manual-date-wrapper {
      display: flex;
      flex-direction: column;
      gap: 18px;
    }

    &--manual-date-input {
      display: flex;
      gap: 12px;
      justify-content: space-evenly;
      align-items: center;
    }

    &--select-input {
      &.view {
        .Mui-disabled {
          color: black;
        }
      }

      display: flex;
      flex-direction: row;
      gap: 20px;
      width: 100%;
      margin: 0;
      align-items: center;

      div:first-child {
        flex: 3;
      }

      button:last-child {
        flex: 1;
      }
    }

    &__bottle-batch-input {
      width: 90%;
      display: flex;
      margin: 0 auto;
      gap: 10px;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .input-component-wrapper {
        margin: 0 auto;
      }

      .dropdown-component--wrapper {
        margin: 0 auto;
      }
    }

    .tab-wrapper {
      opacity: 0.1;
    }

    &--completed-check-wrapper {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }

  #loaderOverlay {
    background-color: black;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    opacity: 0.5;
  }

  &--past-break-wrapper {
    padding: 0 5%;
  }

  &--documents-wrapper {
    .button-component-wrapper {
      width: 80%;
    }
  }
}

.product-form {
  &--bottle-filter-components {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    background-color: white;
    border-radius: 0.5rem;
    padding: 20px;
    margin: 16px;
  }

  &--bottle-filter-result {
    opacity: 0.6;
  }
}