.area-config-page {
  margin: 50px 0;
  padding: 12px;

  &-table-button-wrapper {
    display: flex;
    flex-direction: column;

    .button-component-wrapper {
      width: 10%;
      margin: 0 0 0 auto;
    }
  }

  .factory-area-form {
    .error-label {
      color: red;
      width: 90%;
      margin: 20px auto;
    }
    &--button-wrapper {
      display: flex;
      justify-content: space-evenly;
      .button-component-wrapper {
        width: 25%;
      }
      .delete-button {
        background: #9e0000;
      }
    }
  }

  .tab-component-content {
    flex-direction: column;
  }

  .area-break-form {
    .selection-error-label {
      color: red;
    }
    .switch-component--wrapper {
      margin-left: 0;
    }
    .time-container,
    .area-selection-container {
      display: flex;
      gap: 12px;
      align-items: center;
    }

    .button-component-wrapper {
      width: 100%;
    }

    .area-selection-container {
      .button-component-wrapper {
        width: 20%;
        height: 56px;
      }
    }

    .time-container {
      .input-component-wrapper {
        margin-bottom: auto;
        margin-left: 0;
        width: 100%;
      }
    }
  }
  .area-break-form .button-component-wrapper {
    display: flex;
    justify-content: space-evenly;
  }
}
.delete-button {
  background: #9e0000;
}
