.dashboard {
  &-title {
    margin: 50px 0;
  }

  &-page .button-component-wrapper:last-child {
    background-color: white;
    color: black;
    border: 3px black solid;
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
  }
}

div.pop-up-component--children {
  overflow: initial;
}
