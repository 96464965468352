.qr-reader {
  &-container {
    background-color: black;
    color: white;
    position: absolute;
    left: 50%;
    top: 0;
    width: 80%;
    transform: translateX(-50%);
    padding: 16px;
    border-radius: 5px;
  }
  &-header {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
