#search-page-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 90%;
  padding-top: 25px;

  .button-qr-icon {
    width: 10%;
    margin-bottom: 15px;
  }
}
