.drop-area-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  overflow: scroll;

  .drop-area {
    border-radius: 0 0 15px 15px;
    background: white;
    box-shadow: 2px -2px 7px -2px lightgrey, 2px -2px 7px -2px inset lightgrey;
    min-width: 200px;
    display: flex;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .drop-area.active {
    background: #00519e;

    .drop-area__text {
      color: white;
    }
  }

  .drop-area.hover {
    padding: 20px;
    z-index: 1000;
  }
}